






























import { defineComponent, computed, onBeforeMount } from "@vue/composition-api";
import { User, Role, UserCreateBody } from "@/store";
import { SessionGetters } from "@/store/session/index";
import UserForm from "@/components/forms/User.vue";
import {
  useStore,
  useUserService,
  useRoleService,
  useRouter,
} from "@/composables/providers";
import { UserActions, UserGetters } from "@/store/user";
import { RoleGetters, RoleActions } from "@/store/role";

export default defineComponent({
  name: "UsersCreate",
  components: {
    UserForm,
  },
  setup() {
    const store = useStore();
    const userService = useUserService();
    const roleService = useRoleService();
    const router = useRouter();
    const isSiteAdmin = computed<boolean>(() =>
      store.getters[SessionGetters.ROLE] === "site admin" ? true : false
    );
    const roles = computed<Role[]>(() => {
      const displayRoles = store.getters[RoleGetters.ROLES];
      if (!isSiteAdmin.value) {
        return displayRoles.filter((role: Role) => role.id > 1);
      } else {
        return displayRoles;
      }
    });

    async function submit(body: UserCreateBody) {
      const user: User = await store.dispatch(UserActions.CREATE, {
        body,
        service: userService,
      });
      if (user) {
        await store.dispatch(UserActions.SELECT, {
          id: user.id,
          service: userService,
        });
        await router.push({ name: "UserUpdate", params: { id: user.id } });
      }
    }

    const usersLoading = computed<boolean>(
      () => store.getters[UserGetters.LOADING]
    );
    const rolesLoading = computed<boolean>(
      () => store.getters[RoleGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => usersLoading.value || rolesLoading.value
    );

    onBeforeMount(async () => {
      await store.dispatch(RoleActions.LIST, { service: roleService });
    });

    return {
      roles,
      submit,
      loading,
    };
  },
});
